import React from "react"
import Layout from "@/components/layout"
import EventoEnem from "@/components/evento-enem"
import Footer from "@/components/footer"

const SucessPage = () => {
  return (
    <Layout>
      <EventoEnem />
      <Footer />
    </Layout>
  );
};

export default SucessPage;
