/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoSerios from '../assets/images/lp/logotipo_serios.svg'
import TicketImg from '../assets/images/lp/ticket-icon.svg'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

import { 
    titleEvent,
    subtitleEvent,
    content
  }  from '../assets/styles/EventoEnem'

const ContentSection = () => {
  return (
    <section css={sucessContent}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoSerios} alt="Colégio Seriös" title="Colégio Seriös" /></div>
            <div css={content}>
              {/* <div style={{ textAlign: 'center' }}><img src={SucessoImg} /></div> */}
              <h1 css={titleEvent}>Aulão ENEM</h1>
              <h2 css={subtitleEvent}><span>29</span> de outubro</h2>
              <a href="https://www.asaas.com/c/392864051070"><img src={TicketImg} /> garanta sua vaga</a>
            </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
